// @flow
/* eslint-disable no-unused-vars */
import * as React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar} from "@mui/material"
import Cookies from "js-cookie";

declare var window: EventTarget | null
declare var document: Document | null

type CookieConsentProps = {
    componentType?: 'Dialog' | 'Snackbar',
    cookieName: string,
    cookieValue: string,
    acceptOnScroll?: boolean,
    acceptOnScrollPercentage?: number,
    onAccept?: () => void | null,
    expires?: number | Date,
    hideOnAccept?: boolean,
    children: React.ReactElement,
    title?: string | null,
    message?: string,
    acceptButtonLabel?: string,
    debug?: boolean,
    extraCookieOptions?: any,
    snackbarAnchor?: {
        horizontal: 'left' | 'center' | 'right',
        vertical: 'top' | 'bottom',
    },
    actions?: any,
};
type State = {
    visible: boolean,
};

/**
 * This component is the MUICookieConsent it pops a Snackbar or a Dialog informing the user about cookie consent.
 */
export default class CookieConsent extends React.Component<CookieConsentProps, State> {
    static defaultProps = {
        componentType: 'Snackbar',
        cookieValue: "CarServiceERP-Cookie",
        acceptOnScroll: false,
        acceptOnScrollPercentage: 25,
        expires: 365,
        hideOnAccept: true,
        debug: false,
        extraCookiesOptions: undefined,
        snackbarAnchor: {horizontal: 'center', vertical: 'bottom'},
        children: null,
        message: 'Az oldal technikai és kényelmi funkciókhoz Sütiket (Cookies) használ!',
        title: null,
        acceptButtonLabel: 'Elfogadás',
        actions: null,
    };

    constructor(props: CookieConsentProps) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        const {cookieName, debug, acceptOnScroll} = this.props;

        if (Cookies.get(cookieName) === undefined || debug) {
            this.setState({visible: true});
        }

        if (window && acceptOnScroll) {
            window.addEventListener('scroll', this.handleScroll, {passive: true});
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    /**
     * checks whether scroll has exceeded set amount and fire accept if so.
     */
    handleScroll = () => {
        const {acceptOnScrollPercentage} = this.props;
        if (document && typeof acceptOnScrollPercentage === 'number') {
            const rootNode = document.documentElement || document.body;

            if (rootNode) {
                // (top / (height - height)) * 100
                const percentage =
                    (rootNode.scrollTop /
                        (rootNode.scrollHeight - rootNode.clientHeight)) *
                    100;

                if (percentage > acceptOnScrollPercentage) {
                    this.handleAccept();
                }
            }
        }
    };

    /**
     * Set a persistent cookie
     */
    handleAccept = () => {
        const {
            cookieName,
            cookieValue,
            expires,
            hideOnAccept,
            onAccept,
            extraCookieOptions,
        } = this.props;

        if (onAccept) {
            onAccept();
        }

        if (window) {
            window.removeEventListener('scroll', this.handleScroll);
        }

        Cookies.set(cookieName, cookieValue, {expires, ...extraCookieOptions});

        if (hideOnAccept) {
            this.setState({visible: false});
        }
    };

    render() {
        const {
            componentType,
            children,
            message,
            snackbarAnchor,
            title,
            acceptButtonLabel,
            actions,
        } = this.props;


        // @ts-ignore
        const childrenWithProps = React.Children.map(children, (child, _) =>
            React.cloneElement(child, {onAccept: this.handleAccept}),
        );


        switch (componentType) {
            case 'Snackbar':
                return children ? (
                    <Snackbar anchorOrigin={snackbarAnchor} open={this.state.visible}>
                        <React.Fragment>
                            "childrenWithProps"
                        </React.Fragment>
                    </Snackbar>
                ) : (
                    <Snackbar
                        anchorOrigin={snackbarAnchor}
                        open={this.state.visible}
                        message={<span id="message-id">{message}</span>}
                        action={[
                            ...React.Children.toArray(actions),
                            <Button
                                key="accept"
                                color="secondary"
                                size="small"
                                onClick={this.handleAccept}
                            >
                                {acceptButtonLabel}
                            </Button>,
                        ]}
                    />
                );
            case 'Dialog':
                return (
                    <Dialog open={this.state.visible}>
                        {children ? (
                            childrenWithProps
                        ) : (
                            <>
                                {title ? <DialogTitle>{title}</DialogTitle> : null}
                                <DialogContent>
                                    <DialogContentText
                                        id="alert-dialog-description"
                                        component="div"
                                    >
                                        {message}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    {actions}
                                    <Button onClick={this.handleAccept} color="secondary">
                                        {acceptButtonLabel}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                );
            default:
                return null;
        }
    }
}
