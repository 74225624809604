import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {Field, FieldArray, Form, Formik} from "formik";
import Box from "@mui/material/Box";
import {
    Button,
    FormControlLabel,
    FormGroup,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import BusinessTimeModel from "../../../models/BusinessTimeModel";
import {plainToClass} from "class-transformer";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Switch} from "formik-mui";
import {MyTimePicker} from "../../components/MyTimePicker";
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyDateTimePicker} from "../../components/MyDateTimePicker";
import {MyTextField} from "../../components/MyTextField";
import {crudService} from "../../../services/CrudService";
import {ApiEndpoints} from "../../../services/IService";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(16, 31, 51)',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

type BusinessTimeProps = {}

export function BusinessTime(_: BusinessTimeProps) {

    const [isLoading, setIsLoading] = useState(true)
    const [businessTime, setBusinessTime] = useState(new BusinessTimeModel())

    useEffectOnce(() => {
        crudService.get(ApiEndpoints.BusinessTime, "", (data: object[]) => {
            setBusinessTime(plainToClass(BusinessTimeModel, data[0]))
            setIsLoading(false)
            console.log(data)
        })
    })

    return isLoading ? (
        <React.Fragment>
            <h4>Loading...</h4>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Formik
                initialValues={{
                    businessTimeModel: businessTime,
                }}
                onSubmit={(values) => {
                    alert(JSON.stringify(values))
//                    productService.update("/product/1", JSON.stringify(values.productMembers))
                }}
                validateOnChange={false}    // if true, gets fucking slow ;)
            >
                {({values, handleSubmit, handleChange, handleBlur, errors}) => (
                    <Form>
                        <Box>
                            <FormGroup>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TableContainer>
                                        <Table sx={{minWidth: 700}} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Rendszeres heti nyitvatartás</StyledTableCell>
                                                    <StyledTableCell>Nyitás</StyledTableCell>
                                                    <StyledTableCell>Zárás</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isMon"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Hétfő"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.mon[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isMon}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.mon[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isMon}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isTue"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Kedd"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.tue[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isTue}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.tue[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isTue}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isWed"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Szerda"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.wed[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isWed}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.wed[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isWed}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isThu"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Csütörtök"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.thu[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isThu}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.thu[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isThu}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isFri"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Péntek"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.fri[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isFri}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.fri[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isFri}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isSat"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Szombat"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.sat[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isSat}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.sat[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isSat}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={<Field
                                                                name={"businessTimeModel.standardHours.isSun"}
                                                                component={Switch} type={"checkbox"} disabled={false}/>}
                                                            label="Vasárnap"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.sun[0]"}
                                                               disabled={!values.businessTimeModel.standardHours.isSun}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Field name={"businessTimeModel.standardHours.sun[1]"}
                                                               disabled={!values.businessTimeModel.standardHours.isSun}
                                                               component={MyTimePicker}/>
                                                    </TableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer>
                                        <Table sx={{minWidth: 700}} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Munkanapok / Egyedi nyitvatartás</StyledTableCell>
                                                    <StyledTableCell>Nap - Nyitás</StyledTableCell>
                                                    <StyledTableCell>Zárás</StyledTableCell>
                                                    <StyledTableCell>Törlés</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <FieldArray name={"businessTimeModel.workDays"}>
                                                    {({insert, remove, push}: any) => (
                                                        <>
                                                            {values.businessTimeModel.workDays.map((value, index) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <TableCell>
                                                                            <Field
                                                                                name={`businessTimeModel.workDays[${index}].description`}
                                                                                component={MyTextField} disabled={false}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Field name={`businessTimeModel.workDays[${index}].open`}
                                                                                   disabled={false}
                                                                                   component={MyDateTimePicker}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Field name={`businessTimeModel.workDays[${index}].close`}
                                                                                   disabled={false}
                                                                                   component={MyTimePicker}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Button onClick={() => remove(index)}>
                                                                                Törlés
                                                                            </Button>
                                                                        </TableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            })}
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                <Button onClick={() => push({
                                                                    workDays: new BusinessTimeModel().workDays,
                                                                })}>
                                                                    -- Új --
                                                                </Button>
                                                            </TableCell>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <TableContainer>
                                        <Table sx={{minWidth: 700}} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Hivatalos szünnapok</StyledTableCell>
                                                    <StyledTableCell>Nap</StyledTableCell>
                                                    <StyledTableCell></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <FieldArray name={"businessTimeModel.nonWorkDays"}>
                                                    {({insert, remove, push}: any) => (
                                                        <>
                                                            {values.businessTimeModel.nonWorkDays.map((value, index) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <TableCell>
                                                                            <Field
                                                                                name={`businessTimeModel.nonWorkDays[${index}].description`}
                                                                                component={MyTextField} disabled={true}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Field name={`businessTimeModel.nonWorkDays[${index}].day`}
                                                                                   disabled={true} component={MyDatePicker}/>
                                                                        </TableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </LocalizationProvider>
                            </FormGroup>
                        </Box>
                        <Box style={{width: "200px", margin: "5px"}}>
                            <Button color={"primary"} variant={"contained"} type={"submit"}>Mentés</Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}