import IModel from "./IModel";

class PersonalAppointmentModel implements IModel {

    // <div>szolgáltató kiválsztás, "feladat igény", időpont foglalás [statusz - foglalva, visszaigazolva, visszautasítva]</div>
    id: number = 0
    providerId: number = 0
    providerName: string = ""
    dateTime: Date = new Date(2000,1,1, 12,0,0)
    eventType: number = 0
    eventDescription: string = ""

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }

}

export default PersonalAppointmentModel