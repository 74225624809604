import IModel from "./IModel";
import AddressModel from "./AddressModel";
import BusinessDetailsModel from "./BusinessDetailsModel";

type WorkPlaceModel = {

// <div>beállások tulajdonságai (emelő, csőrös stb)</div>
    address: AddressModel
    type: string
    description: string
    active: boolean
}

export default class BusinessSetupModel implements IModel {

    business: BusinessDetailsModel = new BusinessDetailsModel()
    setups: Array<WorkPlaceModel> = []

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }
}