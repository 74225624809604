import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {Field, Form, Formik} from "formik";
import PersonalAppointmentModel from "../../../models/PersonalAppointmentModel";
import Box from "@mui/material/Box";
import {MyTextField} from "../../components/MyTextField";
import {Button} from "@mui/material";
import {crudService} from "../../../services/CrudService";
import {plainToClass} from "class-transformer";
import {ApiEndpoints} from "../../../services/IService";

type PersonalAppointmentProps = {}

export function PersonalAppointment(props: PersonalAppointmentProps) {

    const [isLoading, setIsLoading] = useState(true)
    const [personalAppointment, setPersonalAppointment] = useState(new PersonalAppointmentModel())

    useEffectOnce(() => {
        crudService.get(ApiEndpoints.PersonalAppointment, "", (data: object[]) => {
            setPersonalAppointment(plainToClass(PersonalAppointmentModel, data[0]))
            setIsLoading(false)
            console.log(data)
        })
    })

    return isLoading ? (
        <React.Fragment>
            <h4>Loading...</h4>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Formik
                initialValues={{
                    personalAppointmentModel: personalAppointment,
                }}
                onSubmit={(values) => {
                    alert(JSON.stringify(values))
//                    productService.update("/product/1", JSON.stringify(values.productMembers))
                }}
            >
                {({values, handleSubmit, handleChange, handleBlur, errors}) => (
                    <Form>
                        <Box>
                            <Field name={`personalDetailsModel.id`} label={"Azonosító"} component={MyTextField}
                                   disabled={true}/>
                        </Box>
                        <Box>
                        </Box>
                        <Box style={{width: "200px", margin: "5px"}}>
                            <Button color={"primary"} variant={"contained"} type={"submit"}>Mentés</Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}