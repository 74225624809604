import React from "react";
import {ICellRendererReactComp} from "ag-grid-react";
import {ICellRendererParams} from "ag-grid-community";
import {Link} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const item = {
    py: '5px',
    px: 7,
    color: 'black',
    '&:hover, &:focus': {
        bgcolor: 'grey',
    },
};

export class MyTableCellRenderer extends React.Component<ICellRendererParams> implements ICellRendererReactComp {

    // This class will fail to compile unless the `agInit` method below is uncommented.
    //
    // The docs say that `agInit` is required for Angular & Polymer, but NOT REACT:
    // https://www.ag-grid.com/javascript-grid-cell-rendering-components
    //
    // However, `agInit` seems to have been added as a non-optional field to the TypeScript interface here:
    // https://github.com/ag-grid/ag-grid/commit/66891164d6c7952f0574c6e3ebcc1f0a95a427f7#diff-b4197d2ae9b291cc4918e217aeddbc00R22
    // and this is affecting React as well.
    //
    // public agInit() {
    //   throw new Error(`This method is not used in React! But it's now required in the type declaration?!`);
    // }
    constructor(props: ICellRendererParams) {
        super(props);
        this.state = {};
    }
    getValue = () => {
        return this.props.value;
    };
    public refresh(params: any) {
        console.log(params)
        return true;
    }

    public render() {
        return <Link to={"/personalcardetails/" + this.props.value} style={{ textDecoration: 'none' }}>
            <ListItem disablePadding key={"/personalcardetails/" + this.props.value}>
                <ListItemButton sx={item}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText> {this.props.value} </ListItemText>
                </ListItemButton>
            </ListItem>
        </Link>
    }
}

