import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import oauthService from "../../services/OauthService";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";

const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
    onDrawerToggle: () => void;
}

export default class HeaderLoggedIn extends React.Component<HeaderProps> {

    constructor(props: HeaderProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <React.Fragment>
                <AppBar color="primary" position="sticky" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={1} alignItems="center">
                            <Grid sx={{display: {sm: 'none', xs: 'block'}}} item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.props.onDrawerToggle}
                                    edge="start"
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item xs/>
                            <Grid item>
                                <Button href={"/userdata"}>
                                    {oauthService.getUserEmail()} - {oauthService.getUsername()}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button href={""}  onClick={() => {
                                    oauthService.accountManagement()
                                }}>
                                    Fiók beállítások
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button href={""} onClick={() => {
                                    oauthService.logout()
                                }}>
                                    Kilépés
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton color="inherit" sx={{p: 0.5}}>
                                    <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        );
    }
}
