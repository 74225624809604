import IModel from "./IModel";

class AddressModel implements IModel {

    country: string = ""
    state: string = ""
    county: string = ""
    zipCode: string = ""
    place: string = ""
    street: string = ""
    groundType: string = ""
    streetNumber: string = ""
    building: string = ""
    staircase: string = ""
    floor: string = ""
    door: string = ""

    getApiUrl(): string {
        return ""
    }

    getMembers(): object[] {
        return [
            { field: 'country', headerName: 'Ország', position: 0 },
            { field: 'state', headerName: 'Állam', position: 0 },
            { field: 'county', headerName: 'Megye', position: 0 },
            { field: 'zipCode', headerName: 'Irányítószám', position: 0 },
            { field: 'place', headerName: 'Helység', position: 0 },
            { field: 'street', headerName: 'Közterület', position: 0 },
            { field: 'groundType', headerName: 'Közterület típus', position: 0 },
            { field: 'streetNumber', headerName: 'Házszám', position: 0 },
            { field: 'building', headerName: 'Épület', position: 0 },
            { field: 'staircase', headerName: 'Lépcsőház', position: 0 },
            { field: 'floor', headerName: 'Emelet', position: 0 },
            { field: 'door', headerName: 'Ajtó', position: 0 },
        ]
    }

    parseData(): string {
        return ""
    }
}

export default AddressModel