import {baseUrl, HttpService} from "./HttpService";
import {ApiEndpoints, IService} from "./IService";
import IModel from "../models/IModel";
import {AxiosResponse} from "axios";

class CrudService extends HttpService implements IService {

    constructor() {
        super();
    }

    post(api: ApiEndpoints, data: IModel, callback: (status : number, data: object[]) => void): void {
        this.httpClient.post(baseUrl + api, data)
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp.data)
            })
            .catch(error => {
                const statusCode = this.handleError(error, (success) => {
                    if (success) {
                        this.post(api, data, callback)
                    }
                })
                if (statusCode === 500) {
                    callback(statusCode, error)
                }
            })
    }

    get(api: ApiEndpoints, param: string | undefined, callback: (data: object[]) => void) {
        let url = baseUrl + api
        if (param && param.length !== 0) {
            url += "/" + param
        }
        this.httpClient.get(url)
            .then((resp: AxiosResponse) => {
                callback(resp.data)
            })
            .catch(error => {
                this.handleError(error, (success) => {
                    if (success) {
                        this.get(api, param, callback)
                    }
                })
            })
    }

    patch(api: ApiEndpoints, id: string, data: IModel): void {
        this.httpClient.patch(baseUrl + api, "data")
            .then()
            .catch()
    }

    delete(api: ApiEndpoints, id: string): void {
        this.httpClient.delete(baseUrl + api)
            .then()
            .catch()
    }
}

export const crudService = new CrudService()