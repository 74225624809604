import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import HeaderLoggedIn from './HeaderLoggedIn';
import oauthService from "../../services/OauthService";
import {Copyright} from "./Copyright";
import {VerifyEmail} from "./VerifyEmail";
import {ContentContainer} from "./ContentContainer";
import {myTheme} from "../../theme/MyTheme";

const drawerWidth = 256;

type AppBaseProps = {

}

type AppBaseState = {

    mobileOpen: boolean;
}

//var isSmUp: useMediaQuery(theme.breakpoints.up('sm'));

export default class AppBase extends React.Component<AppBaseProps, AppBaseState> {

    handleDrawerToggle = function () {
    };
    isSmUp : boolean;

    constructor(props: AppBaseProps) {
        super(props);
        this.state = {
            mobileOpen: false,
        }
        this.handleDrawerToggle = function ()  {
            this.setState({
                mobileOpen: !false
            });
        };
        this.isSmUp = false; // useMediaQuery(theme.breakpoints.up('sm'));
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        if (!oauthService.isLoggedIn()) {
            return null;
        } else if (!oauthService.isEmailVerified()) {
            return <VerifyEmail />
        }
        return (
            <ThemeProvider theme={myTheme}>
                <Box sx={{display: 'flex', minHeight: '100vh'}}>
                    <CssBaseline/>
                    <Box
                        component="nav"
                        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                    >
                        {this.isSmUp ? null : (
                            <Navigator
                                PaperProps={{style: {width: drawerWidth}}}
                                variant="temporary"
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                            />
                        )}
                        <Navigator
                            PaperProps={{style: {width: drawerWidth}}}
                            sx={{display: {sm: 'block', xs: 'none'}}}
                        />
                    </Box>
                    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <HeaderLoggedIn onDrawerToggle={this.handleDrawerToggle}/>
                        <ContentContainer />
                        <Copyright />
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}