import React, {useState} from "react";
import ProductModel from "../models/ProductModel";
import {Field, Form, Formik} from "formik";
import {Button, MenuItem} from "@mui/material";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import Box from "@mui/material/Box";

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {MyTextField} from "./components/MyTextField";
import {MySelect} from "./components/MySelect";
import {plainToClass} from "class-transformer";
import {crudService} from "../services/CrudService";
import {ApiEndpoints} from "../services/IService";

enum EQuantitativeUnit {
    None,
    Month,
    Hour,
    Occasion, // alkalom
    Piece,
    Gram,
    kiloGramm,
}

enum ETypeProduct {

    None,
    TypeService,
    TypeProduct,
}

type ProductFormProps = {

}

export function ProductForm(props: ProductFormProps) {

    const [isLoading, setIsLoading] = useState(true)
    const [product, setProduct] = useState<ProductModel>(new ProductModel())

    useEffectOnce(() => {

        crudService.get(ApiEndpoints.Product, "1", (data: object[]) => {
            console.log(data)
            setProduct(plainToClass(ProductModel, data[0]))
            setIsLoading(false)
        })
    })

// formik.values.productMembers.getMembers()[0]
    return isLoading ? (
            <React.Fragment>
                <h4>Loading...</h4>
            </React.Fragment>
        ):(
        <React.Fragment>
            <div>
                <Formik
                    initialValues={{
                    productMembers: product,
                }}
                    onSubmit={(values) => {
                        alert(JSON.stringify(values))
                        /*
                        crudService.patch("/product/1", JSON.stringify(values.productMembers))

                         */
                    }}
                >
                    {({values, handleSubmit, handleChange, handleBlur, errors}) =>(
                        <Form>
                            <Box>
                                <Field name={`productMembers.id`} component={MyTextField} label={"Azonosító"} disabled={true}/>
                            </Box>
                            <Box>
                                <Field name={`productMembers.name`} component={MyTextField} label={"Megnevezés"} />
                                <Field name={`productMembers.description`} component={MyTextField} label={"Leírás"} />
                            </Box>
                            <Box>
                                <Field label={"Mennyiségi egység"} name={`productMembers.quantitativeUnit`} displayEmpty={true} component={MySelect}>
                                    <MenuItem value={"None"}>None</MenuItem>
                                    <MenuItem value={"Month"}>Havi</MenuItem>
                                    <MenuItem value={"Hour"}>Óra</MenuItem>
                                    <MenuItem value={"Occasion"}>Alkalom</MenuItem>
                                    <MenuItem value={"Piece"}>Darab</MenuItem>
                                    <MenuItem value={"Gramm"}>Gramm</MenuItem>
                                    <MenuItem value={"kiloGramm"}>Kilógramm</MenuItem>
                                </Field>
                            </Box>
                            <Box style={{width: "200px", margin: "5px"}}>
                                <Button color={"primary"} variant={"contained"} type={"submit"}
                                        disabled={!errors}>Mentés</Button>
                            </Box>
                        </Form>

                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}
