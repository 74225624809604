import {FieldProps, getIn, useFormikContext} from "formik";
import {TimePicker} from "@mui/x-date-pickers";
import React from "react";

export function MyTimePicker({field, form: {errors}, ...props}: FieldProps) {

    const {setFieldValue} = useFormikContext();

    const errorMessage = getIn(errors, field.name)
    return <TimePicker ampm={false}
                       ampmInClock={false}
                       value={field.value}
                       {...props}
                       slotProps={{ textField:{size:"small", sx:{width: '120px', paddingRight: '5px'} }}}
                       onChange={(value) => setFieldValue(field.name, value)}
    />
}