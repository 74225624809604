import IModel from "./IModel";

/**
 * Day - open time | close time
 */
class WeekdaysModel {
// https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.dribbble.com%2Fusers%2F223690%2Fscreenshots%2F15579479%2Fmedia%2F451c4c553e06ad3dd5750839fa3d06f0.png%3Fcompress%3D1%26resize%3D400x300&imgrefurl=https%3A%2F%2Fdribbble.com%2Ftags%2Fbusiness_hours&tbnid=UibI_k56YY60LM&vet=12ahUKEwj_oruv_7j7AhWatqQKHRjTC-UQMygAegUIARDaAQ..i&docid=YcijvhQpdW6iMM&w=400&h=300&q=ui%20ux%20working%20hours&hl=en-US&ved=2ahUKEwj_oruv_7j7AhWatqQKHRjTC-UQMygAegUIARDaAQ#imgrc=UibI_k56YY60LM&imgdii=-sMOleNgYHF2RM
    isMon = false
    mon: [Date, Date] = [new Date("1900-01-01T08:00:00"), new Date("1900-01-01T16:30:00")]
    isTue = false
    tue: [Date, Date] = [new Date("1900-01-01T08:00:00"), new Date("1900-01-01T16:30:00")]
    isWed = false
    wed: [Date, Date] = [new Date("1900-01-01T08:00:00"), new Date("1900-01-01T16:30:00")]
    isThu = false
    thu: [Date, Date] = [new Date("1900-01-01T08:00:00"), new Date("1900-01-01T16:30:00")]
    isFri = false
    fri: [Date, Date] = [new Date("1900-01-01T08:00:00"), new Date("1900-01-01T16:00:00")]
    isSat = false
    sat: [Date, Date] = [new Date("1900-01-01T09:00:00"), new Date("1900-01-01T14:00:00")]
    isSun = false
    sun: [Date, Date] = [new Date("1900-01-01T10:00:00"), new Date("1900-01-01T13:00:00")]
}

class WorkDay {
    description: string = ""
    open: Date = new Date()
    close: Date = new Date()
}

class Holiday {
    description: string = ""
    day: Date = new Date()
}

class BusinessTimeModel implements IModel {

    id: number = 0
    standardHours: WeekdaysModel = new WeekdaysModel()
    workDays: Array<WorkDay> = [
        {
            description: "Mert dolgozni kell",
            open: new Date( "2022-12-01T08:00:00"),
            close: new Date("2022-12-01T16:30:00")
        },
        {
            description: "Mert dolgozni sem kell",
            open: new Date( "2022-12-02T09:00:00"),
            close: new Date("2022-12-02T14:00:00")
        }
    ]
    nonWorkDays: Array<Holiday> = [
        {description: "Karácsony", day: new Date("2022-12-23")},
        {description: "Karácsony", day: new Date("2022-12-24")},
        {description: "Karácsony", day: new Date("2022-12-25")}
    ]
// <div>hivatalos szünnapok és munkanapok, nyitás - zárás, egyedi nyitvatartás, foglalási időszak</div>

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }

}

export default BusinessTimeModel