import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {Field, FieldArray, Form, Formik} from "formik";
import BusinessDetailsModel from "../../../models/BusinessDetailsModel";
import {MyTextField} from "../../components/MyTextField";
import AddressModel from "../../../models/AddressModel";
import {crudService} from "../../../services/CrudService";
import {ApiEndpoints} from "../../../services/IService";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

type BusinessDetailsProps = {}

export function BusinessDetail(props: BusinessDetailsProps) {

    const [isLoading, setIsLoading] = useState(true)
    const [businessDetails, setBusinessDetails] = useState(new BusinessDetailsModel())

    useEffectOnce(() => {
        crudService.get(ApiEndpoints.BusinessDetails, "", (data: object[]) => {
            setBusinessDetails(new BusinessDetailsModel()) // plainToClass(BusinessDetailsModel, data[0]))
            setIsLoading(false)
            console.log(data)
        })
    })

    return isLoading ? (
        <React.Fragment>
            <h4>Loading...</h4>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Grid container>
                <Grid item sm={3}></Grid>
                <Grid item sm={6}>
                    <Paper>
                        <Box m={1} p={1}>
                            <Formik
                                initialValues={{
                                    businessDetailsModel: businessDetails,
                                }}
                                onSubmit={(values) => {
                                    alert(JSON.stringify(values))
//                    productService.update("/product/1", JSON.stringify(values.productMembers))
                                }}
                            >
                                {({values, handleSubmit, handleChange, handleBlur, errors}) => (
                                    <Form>
                                        <Box>
                                            <Field name={`personalDetailsModel.id`} label={"Azonosító"}
                                                   component={MyTextField} disabled={true}/>
                                        </Box>
                                        <Box>
                                            <Field name={`personalDetailsModel.name`} label={"Név"}
                                                   component={MyTextField}/>
                                            <Field name={`personalDetailsModel.address`} label={"Cím"}
                                                   component={MyTextField}/>
                                        </Box>
                                        <Box>
                                            <FieldArray name={"businessDetailsModel.address"}>
                                                {({insert, remove, push}: any) => (
                                                    <div>
                                                        <div>
                                                            {values.businessDetailsModel.addresses.length > 0 &&
                                                                values.businessDetailsModel.addresses.map((value, index) => {
                                                                    return <div key={index}>
                                                                        <Box>
                                                                            <Field
                                                                                name={`businessDetailsModel.addresses[${index}].id`}
                                                                                component={MyTextField}
                                                                                label={"Azonosító"} disabled={true}/>
                                                                            <Field
                                                                                name={`businessDetailsModel.addresses[${index}].country`}
                                                                                component={MyTextField}
                                                                                label={"Ország"}/>
                                                                            <Field
                                                                                name={`businessDetailsModel.addresses[${index}].zipCode`}
                                                                                component={MyTextField}
                                                                                label={"Irányítószám"}/>
                                                                            <Field
                                                                                name={`businessDetailsModel.addresses[${index}].county`}
                                                                                component={MyTextField}
                                                                                label={"Megye"}/>
                                                                            <Field
                                                                                name={`businessDetailsModel.addresses[${index}].place`}
                                                                                component={MyTextField}
                                                                                label={"Helység"}/>
                                                                            <Button onClick={() => remove(index)}>
                                                                                Remove
                                                                            </Button>
                                                                        </Box>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                        <Button onClick={() => push({
                                                            address: new AddressModel(),
                                                        })}>
                                                            Add item
                                                        </Button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Box>
                                        <Box style={{width: "200px", margin: "5px"}}>
                                            <Button color={"primary"} variant={"contained"}
                                                    type={"submit"}>Mentés</Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}