import Keycloak, {KeycloakConfig, KeycloakInitOptions} from "keycloak-js";
import Cookies from "js-cookie";
import {frontendServerUrl, oauthServerUrl} from "./HttpService";

class OauthService {

    config : KeycloakConfig = {
        url: oauthServerUrl,
        realm: 'CarService',
        clientId: 'carserviceerp'
    }
    initOptions : KeycloakInitOptions = {
        onLoad: 'check-sso',
//        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        redirectUri: frontendServerUrl,
        checkLoginIframe: false,
    }
    keycloak : Keycloak;

    constructor() {
        this.keycloak = new Keycloak(this.config)
    }
    // Call it from index.tsx
    appInit(rootRender : () => void) {

        this.keycloak.init(this.initOptions)
            .then((authenticated) => {
                rootRender()
            })
            .catch(console.error)

    }
    login() {
        this.keycloak.login({
//            redirectUri: oauthServerUrl + window.location.pathname
        })
        /*
        redirect...
        .then((authenticated:void) => {
            alert("login res " + authenticated);
        })
        .catch((error) => {
            alert("failure " + error);
        })

         */
    }
    logout() {
        this.keycloak.logout({redirectUri: frontendServerUrl})
    }
    getToken() : string | undefined {
        return this.keycloak.token;
    }
    getUsername() : string | undefined {
        return this.keycloak.tokenParsed?.preferred_username;
    }
    getUserEmail() : string | undefined {
        return this.keycloak.tokenParsed?.email;
    }
    refreshToken(successCallback : (result : boolean) => void) {
        this.keycloak.updateToken(5)
            .then(successCallback)
            .catch(error => {
                console.log("fail to refresh")
                //alert(window.location.pathname)
                Cookies.set("ReLoginRestorePrevLocation", window.location.pathname, {expires:1});
                this.login()
            })
    }
    isLoggedIn() : boolean {
        return this.keycloak.authenticated ?? false
    }
    registration() {
        return this.keycloak.register({
            redirectUri: frontendServerUrl,
        })
    }
    isEmailVerified() {
        return this.keycloak.tokenParsed?.email_verified
    }
    accountManagement() {
        this.keycloak.accountManagement().then(() => {

        })
    }
}
const oauthService = new OauthService();
export default oauthService;