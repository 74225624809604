import React from "react";
import oauthService from "../../services/OauthService";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Header from "./Header";
import {WhatIsThis} from "./WhatIsThis";
import {SignUp} from "./SignUp";
import {PriceList} from "./PriceList";
import CssBaseline from "@mui/material/CssBaseline";
import {Copyright} from "../authenticated/Copyright";
import {myTheme} from "../../theme/MyTheme";

const darkTheme = createTheme({
    palette: {
        mode: 'light',

    },

});

type WelcomeProps = {}

export function Welcome(props: WelcomeProps) {

    if (oauthService.isLoggedIn()) {
        return null;
    }
    return <ThemeProvider theme={myTheme}>
        <CssBaseline />
        <Header jah={() => {
        }}/>
        <WhatIsThis/>
        <PriceList/>
        <SignUp/>
        <Copyright />
    </ThemeProvider>

}