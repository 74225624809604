import {FieldProps, getIn, useFormikContext} from "formik";
import {DatePicker} from "@mui/x-date-pickers";
import React from "react";

export function MyDatePicker({field, form: {errors}, ...props}: FieldProps) {

    const {setFieldValue} = useFormikContext();

    const errorMessage = getIn(errors, field.name)
    return <DatePicker value={field.value}
                       format={"yyyy-MM-dd"}
                       {...props}
                       slotProps={{ textField:{size:"small", sx:{width: '140px'} }}}
                       onChange={(value) => setFieldValue(field.name, value)}
    />
}