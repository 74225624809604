import React, {useCallback, useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {Calendar, dateFnsLocalizer, Event, Views} from "react-big-calendar";
import withDragAndDrop, {withDragAndDropProps} from "react-big-calendar/lib/addons/dragAndDrop";

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {enUS} from "@mui/material/locale";
import {addHours, format, getDay, parse, startOfHour, startOfWeek} from "date-fns";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const locales = {
    'en-US': enUS,
}
const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
const now = new Date()
const start = endOfHour(now)
const end = addHours(start, 2)
// The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})
const DnDCalendar = withDragAndDrop(Calendar)

type BusinessTimeTableProps = {}

export function BusinessTimeTable (props : BusinessTimeTableProps) {

    // <Calendar>
    const [events, setEvents] = useState<Event[]>([
        {
            title: 'Learn cool stuff',
            start,
            end,
            resource: [100, "Csirkebaszó hétvége", 1.1]
        },
    ])
    const onEventResize: withDragAndDropProps['onEventResize'] = data => {
        const { start, end } = data

        setEvents(currentEvents => {
            const firstEvent = {
                start: new Date(start),
                end: new Date(end),
            }
            return [...currentEvents, firstEvent]
        })
    }
    const onEventDrop: withDragAndDropProps['onEventDrop'] = data => {
        console.log(data)
    }
    // </Calendar>

    useEffectOnce(() => {

    })
    const handleSelectSlot = useCallback(
        ({ start, end }:any) => {
            const title = window.prompt('New Event name')
            if (title) {
                setEvents((prev) => [...prev, { start, end, title }])
            }
        },
        [setEvents]
    )
    const handleSelectEvent = useCallback(
        (event: any) => window.alert(event.title),
        []
    )

    return (
        <React.Fragment>
            <Grid container>
                <Grid item sm={9}>
                    <Paper>
                        <Box m={1} p={1}>
            <Calendar
                defaultView={Views.MONTH}
                views={{month:true, week:false}}
                events={events}
                localizer={localizer}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                selectable
//                onEventDrop={onEventDrop}
//                onEventResize={onEventResize}
//                resizable
                style={{ height: '40vh', width: '55vh' }}
            />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={2}></Grid>
            </Grid>
        </React.Fragment>
    )
}