import React from "react";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {useFormik} from "formik";
import oauthService from "../../services/OauthService";

type SignUpProps = {}

export function SignUp(props: SignUpProps) {

    const formik = useFormik({
        initialValues: {
            email: String(),
            roles: [],
            toggle: Boolean,
            type: Number,
            healthBackground: []
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values))
            oauthService.registration()
        },
    })

    return (
        <React.Fragment>
                <div>
                <form onSubmit={formik.handleSubmit}>
                    <Box>
                        Szolgáltatóként pár kattintással regisztrálhat, mindössze egy működő e-mail címre lesz szükség
                    </Box>
                    <Box style={{width: "200px", margin: "5px"}}>
                        <Button color={"primary"} variant={"contained"} type={"submit"}
                                disabled={!formik.isValid}>Regisztráció</Button>
                    </Box>
                </form>
            </div>
        </React.Fragment>
    );
}
