import PersonalAppointmentModel from "./PersonalAppointmentModel";
import IModel from "./IModel";

class PersonalTimeTableModel implements IModel {

    id: number = 0
    events: Array<PersonalAppointmentModel> = []

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }
}

export default PersonalTimeTableModel