import * as React from "react";
import {useState} from "react";
import Button from '@mui/material/Button';
import {
    Alert,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Paper from '@mui/material/Paper';

type MyMaterialProps = {

//    message: string;
};

type Record = {
    id: number
    value: string
}

let myId = 0

// https://reactjs.org/tutorial/tutorial.html#setup-for-the-tutorial
// https://mui.com/material-ui/getting-started/overview/
export default function MyMaterial(props: MyMaterialProps) {

    const [records, setRecords] = useState<Array<Record>>([])
    const [email, setEmail] = useState("")

    return (
        <div>
            <div>
                <Button variant="text">
                    Add to Cart
                </Button>
                <Button variant="contained">
                    Add to Cart
                </Button>
                <Button variant="outlined">
                    Add to Cart
                </Button>
            </div>
            <div>
                <TextField variant="standard" label="Username" size="small"/>
                <TextField variant="standard" label="Email" type="email" size="small"/>
                <TextField variant="outlined" label="Email" type="email" size="small"/>
                <TextField variant="filled" label="Password" type="password" size="small"/>
                <TextField variant="standard" label=" " type="datetime-local"/>
                <TextField variant="standard" label="Szám" type="number"/>
            </div>
            <div>
                <TextField id={"email"} variant="standard" label="Add Email" type="email" size="small" value={email} onChange={(val) => {
                    setEmail(val.target.value)
                }}/>
                <Button variant="contained" onClick={(event) =>{

                    const recordsTemp = records
                    recordsTemp.push({id: myId++, value: email})
                    setEmail("")
                    setRecords(recordsTemp)
                }}>Add E-Mail to list</Button>
                <Button variant="outlined" onClick={(event) =>{
                    alert(JSON.stringify(records))
                }}>Show my record(s)</Button>
            </div>
            <TableContainer
                component={Paper}
                variant="outlined"
            >
                <Table aria-label="demo table">
                    <TableHead>
                        <TableRow>
                            <TableCell>E-Mail</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((record) => (
                            <TableRow>
                                <TableCell>{record.value}</TableCell>
                                <TableCell>
                                    <Button type="button" variant={"contained"} color={"warning"} onClick={() => {
                                        const recordsTemp = records.filter(rec => rec.id !== record.id)
                                        setRecords(recordsTemp)
                                    }}
                                    >
                                    <span aria-hidden="true">X</span>
                                </Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <Alert variant="standard" color="info">
                    Check out this alert!
                </Alert>
                <Alert variant="outlined" color="info">
                    Check out this alert!
                </Alert>
                <Alert variant="filled" color="info">
                    Check out this alert!
                </Alert>
            </div>
            <div>
                <Tooltip title="This is a tooltip" arrow placement="top">
                    <Typography>Top</Typography>
                </Tooltip>
                <Tooltip title="This is a tooltip" arrow placement="right">
                    <Typography>Right</Typography>
                </Tooltip>
                <Tooltip title="This is a tooltip" arrow placement="left">
                    <Typography>Left</Typography>
                </Tooltip>
                <Tooltip title="This is a tooltip" arrow placement="bottom">
                    <Typography>Bottom</Typography>
                </Tooltip>
            </div>
            <FormControl>
                <InputLabel htmlFor="my-input">Email address</InputLabel>
                <TextField inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}/>
                <Input id="my-input" aria-describedby="my-helper-text"/>
                <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
            </FormControl>
        </div>
    )
}
