import React from "react";
import Box from "@mui/material/Box";
import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";

// https://www.freepik.com/free-photo/muscular-car-service-worker-repairing-vehicle_10519444.htm#query=car%20repair&position=1&from_view=keyword
type WhatIsThisProps = {}

export function WhatIsThis(props: WhatIsThisProps) {

    return (<div style={{padding: "10px"}}>
            <Box sx={{display: 'flex'}}>
                <Card sx={{maxWidth: 345, margin: "10px"}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/car-mechanic.png"
                            alt="car-service"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Autó szervíz
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Akár márkakereskedő vagy szakszervíz, rendszerezze ügyfeleit és teljes folyamatait,
                                csökkentve a papír
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{maxWidth: 345, margin: "10px"}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/car-electrical.png"
                            alt="car-electrical"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Autó villamosági szervíz
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Akár márkakereskedő vagy szakszervíz, rendszerezze ügyfeleit és teljes folyamatait,
                                csökkentve a papír
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Card sx={{maxWidth: 345, margin: "10px"}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/car-body-repair.png"
                            alt="car-body-repair"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Karosszéria javítás? Fényező?
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Akár márkakereskedő vagy szakszervíz, rendszerezze ügyfeleit és teljes folyamatait,
                                csökkentve a papír
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{maxWidth: 345, margin: "10px"}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/car-tyre-change.png"
                            alt="car-tyre-change"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Gumiszervíz
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Akár márkakereskedő vagy szakszervíz, rendszerezze ügyfeleit és teljes folyamatait,
                                csökkentve a papír
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Card sx={{maxWidth: 345, margin: "10px"}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/car-wash.png"
                            alt="car-wash"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Autó polirozás/tisztítás
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Akár márkakereskedő vagy szakszervíz, rendszerezze ügyfeleit és teljes folyamatait,
                                csökkentve a papír
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{maxWidth: 345, margin: "10px"}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/logo192.png"
                            alt="car-logo192"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                -
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                -
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </div>
    );
}