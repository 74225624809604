import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {Tab, Tabs} from "@mui/material";
import {BusinessDetail} from "./providers/BusinessDetail";
import {Route, Routes} from "react-router-dom";
import {BusinessInviteClient} from "./providers/BusinessInviteClient";
import {BusinessSetup} from "./providers/BusinessSetup";
import {BusinessTime} from "./providers/BusinessTime";
import {BusinessTimeTable} from "./providers/BusinessTimeTable";
import {BusinessWarehouse} from "./providers/BusinessWarehouse";
import {PersonalDetail} from "./clients/PersonalDetail";
import {PersonalCars} from "./clients/PersonalCars";
import {PersonalCarDetail} from "./clients/PersonalCarDetail";
import {PersonalAppointment} from "./clients/PersonalAppointment";
import {PersonalTimeTable} from "./clients/PersonalTimeTable";
import {GenericTable} from "../components/GenericTable";
import BusinessDetailsModel from "../../models/BusinessDetailsModel";
import {ApiEndpoints} from "../../services/IService";
import {ProductForm} from "../ProductForm";
import MyMaterial from "../MyMaterial";
import ProductModel from "../../models/ProductModel";

type ContentItems = {

    id : number,
    title : string,
    icon: JSX.Element,
    link: string,
}

let contentArrayProvider : Array<ContentItems> = [
    {id: 0, title:"", icon:<div />, link: ""}
    ]

interface ContentContainerProps {

}

export function ContentContainer(props : ContentContainerProps) {

    const [tabValue, setTabValue] = useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    return (
        <React.Fragment>
            <AppBar component="div" color="primary" position="static" elevation={0} sx={{zIndex: 0}} >
                <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <Typography color="inherit" variant="h5" component="h1">
                                props.title
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AppBar component="div" position="static" elevation={0} sx={{zIndex: 0}}>
                <Tabs value={tabValue} onChange={handleChange} textColor="inherit">
                    <Tab label="Listázás" onClick={() => setTabValue(0)} />
                    <Tab label="Új felvitele" onClick={() => setTabValue(1)} />
                    <Tab label="Szerkesztés" onClick={() => setTabValue(2)} />
                </Tabs>
            </AppBar>
            <Content tab={tabValue} />
        </React.Fragment>
    )
}

interface ContentProps {
    tab : number
}

function Content(props: ContentProps) {


    if (props.tab === 0) {
        return (
            <GenericTable columnDefs={new ProductModel().getMembers()} apiEndpoint={ApiEndpoints.Product} />
        )
    } else {
        return (
            <Routes>
                <Route key={0} path={"/"} element={<div/>}/>
                <Route key={1} path={"/businessdetails"} element={<BusinessDetail />}/>
                <Route key={2} path={"/businessinviteclient"} element={<BusinessInviteClient />}/>
                <Route key={3} path={"/businesssetup"} element={<BusinessSetup />}/>
                <Route key={4} path={"/businesstime"} element={<BusinessTime />}/>
                <Route key={5} path={"/businesstimetable"} element={<BusinessTimeTable />}/>
                <Route key={6} path={"/businesswarehouse"} element={<BusinessWarehouse />}/>
                <Route key={7} path={"/personaldetails"} element={<PersonalDetail />}/>
                <Route key={8} path={"/personalcars"} element={<PersonalCars />}/>
                <Route key={9} path={"/personalcardetails"} element={<PersonalCarDetail />}/>
                <Route key={10} path={"/personalappointment"} element={<PersonalAppointment />}/>
                <Route key={11} path={"/personaltimetable"} element={<PersonalTimeTable />}/>

                <Route key={12} path={"/product_form"} element={<ProductForm />}/>
                <Route key={13} path={"/my_material"} element={<MyMaterial />}/>
            </Routes>
        )
    }
}
/*
export function ContentContainer(props : ContentContainerProps) {
    // FYI <GenericTable key={1} <- remount component!
    return (
        <React.Fragment>
            <ContentHeader title={"Csirke"} />
            <Routes>
                <Route key={0} path={"/"} element={<div />} />
                <Route key={1} path={"/businessdetails"} element={<BusinessDetail />} />
                <Route key={2} path={"/businessinviteclient"} element={<div />} />
                <Route key={3} path={"/businesssetup"} element={<div />} />
                <Route key={4} path={"/businesstime"} element={<div />} />
                <Route key={5} path={"/businesstimetable"} element={<div />} />
                <Route key={6} path={"/businesswarehouse"} element={<div />} />
                <Route key={7} path={"/personaldetails"} element={<div />} />
                <Route key={8} path={"/personalcars"} element={<div />} />
                <Route key={9} path={"/personalcardetails"} element={<div />} />
                <Route key={10} path={"/personalappointment"} element={<div />} />
                <Route key={11} path={"/personaltimetable"} element={<div />} />


            </Routes>
        </React.Fragment>
    )
}
/*
                <Route key={0} path={"/"} element={<div />} />
                <Route key={1} path={"/partners"} element={<GenericTable key={1} columnDefs={new PartnerModel().getMembers()} apiEndpoint={ApiEndpoints.Partner} />} />
                <Route key={2} path={"/partner/:id"} element={<Partner />} />
                <Route key={3} path={"/products"} element={<GenericTable key={2} columnDefs={new ProductModel().getMembers()} apiEndpoint={ApiEndpoints.Product} />} />
                <Route key={4} path={"/product/:id"} element={<Product />} />
                <Route key={3} path={"/projects"} element={<GenericTable key={3} columnDefs={new ProjectModel().getMembers()} apiEndpoint={ApiEndpoints.Project} />} />
                <Route key={4} path={"/project/:id"} element={<Project />} />
                <Route key={5} path={"/warehouses"} element={<Warehouse />} />
                <Route key={6} path={"/product_release"} element={<ProductRelease />} />
                <Route key={7} path={"/product_exposure"} element={<ProductExposure />} />
 */
/*
export default class ContentContainer extends React.Component {

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Paper sx={{maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
                >
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <SearchIcon color="inherit" sx={{display: 'block'}}/>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    placeholder="Search by email address, phone number, or user UID"
                                    InputProps={{
                                        disableUnderline: true,
                                        sx: {fontSize: 'default'},
                                    }}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{mr: 1}}>
                                    Add user
                                </Button>
                                <Tooltip title="Reload">
                                    <IconButton>
                                        <RefreshIcon color="inherit" sx={{display: 'block'}}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Typography sx={{my: 5, mx: 2}} color="text.secondary" align="center">
                    No users for this project yet
                </Typography>
            </Paper>
        );
    }
}
*/
//         path: "contacts/:contactId/edit",
/*
export default class ContentHeader extends React.Component<ContentHeaderProps, ContentHeaderState> {

    constructor(props: ContentHeaderProps) {
        super(props);
        this.state = {
            tabValue: 0,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (<React.Fragment>
                <AppBar
                    component="div"
                    color="primary"
                    position="static"
                    elevation={0}
                    sx={{zIndex: 0}}
                >
                    <Toolbar>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs>
                                <Typography color="inherit" variant="h5" component="h1">
                                    <Routes>
                                    {navigationCategories.map(({id, children}) => (
                                        <Route key={id}>
                                            {children.map(({id: childId, label, icon, link, path, page}) => (
                                                <Route key={childId} path={path} element={<div>{label}</div>}/>
                                            ))}
                                        </Route>
                                    ))}
                                    </Routes>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Routes>
                    {navigationCategories.map(({id, children}) => (
                        <Route key={id}>
                        {children.map(({id: childId, icon, link, path, page}) => (
                            <Route key={childId} path={path} element={page}/>
                        ))}
                        </Route>
                    ))}
                </Routes>
            </React.Fragment>
        )
    }
}

 */
