import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
//// @ts-ignore
//import Userfront from "@userfront/react";
import {useModal} from "mui-modal-provider";
import AppBase from "./pages/authenticated/AppBase";
import {Welcome} from "./pages/anonymous/Welcome";

// https://react-typescript-cheatsheet.netlify.app/docs/basic/setup

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

// @ts-ignore
function App() {
    const {showModal} = useModal();
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
        setSelectedValue(value);
    };

    const eventLogger = (event: unknown, error: unknown) => {
        console.log('onKeycloakEvent', event, error)
    }

    const tokenLogger = (tokens: unknown) => {
        console.log('onKeycloakTokens', tokens)
    }

    return (
        <React.StrictMode>
            <BrowserRouter>
                <Welcome/><AppBase/>
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;