import IModel from "./IModel";

class PersonalDetailsModel implements IModel {

    id: number = 0;
    name: string = "";
    address: string = "";
    phone: string = "";
    email: string = "";

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }

}

export default PersonalDetailsModel