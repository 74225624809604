import {createTheme, ThemeOptions} from '@mui/material/styles';

// https://zenoo.github.io/mui-theme-creator/#Buttons
export const myTheme = createTheme( {
    palette: {
        mode: 'dark',
        primary: {
            main: '#5893df',
        },
        secondary: {
            main: '#2ec5d3',
        },
        background: {
            default: '#192231',
            paper: '#24344d',
        },
    },
})