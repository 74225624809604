import IModel from "./IModel";

class PersonalCarDetailsModel implements IModel {

    id: number = 0;
    fajta: number = 0;  // szgk tgk motor
    make: string = "";
    model: string = "";
    type: string = "";
    productYear: string = "";
    chassisNumber: string = "";
    uzemanyag: number = 0;  // benzin, dízel, elektromos, híbrid, plug-in hybrid
    kmAllas: Array<Date | number> = [new Date(2022,1,1), 0]
    kivitel: string = "";
    szin: string = "";
    szinMetal: number = 0;  // nem, igen, gyöngyház, különleges
    sajatTomeg: number = 0;
    teljesTomeg: number = 0;
    hengerurtartalom: number = 0;
    teljesitmeny: number = 0;
    sebessegvalto: number = 0;
    frsz: string = "";

    // Array - valahogy azonositani!?!?
    gumi_gyarto: string = "";
    gumi_tipus: string = "";
    gumi_gyartasi_ev_ho: number = 0;
    gumimeret_width: number = 0;   // 195
    gumimeret_ratio: number = 0;   // 55
    gumimeret_radius: number = 0;   // 16
    gumi_profil_melyseg: number = 0;

    // Array - felni
    felni_gyartmany: string = "";   // Skoda
    felni_tipus: string = "";       // Octavia III (8247)
    felni_parameter: string = "";   // 6,0Jx16"
    felni_et: string = "";          // ET:48

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }
}

export default PersonalCarDetailsModel