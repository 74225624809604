import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import Box from "@mui/material/Box";
import {MyTextField} from "../../components/MyTextField";
import {Button} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {plainToClass} from "class-transformer";
import PersonalCarDetailsModel from "../../../models/PersonalCarDetailsModel";
import {crudService} from "../../../services/CrudService";
import {ApiEndpoints} from "../../../services/IService";

type PersonalCarDetailsProps = {}

export function PersonalCarDetail (_ : PersonalCarDetailsProps) {

    const [isLoading, setIsLoading] = useState(true)
    const [personalCarDetails, setPersonalCarDetails] = useState(new PersonalCarDetailsModel())

    useEffectOnce(() => {
        crudService.get(ApiEndpoints.PersonalCarDetails, "", (data: object[]) => {
            setPersonalCarDetails(plainToClass(PersonalCarDetailsModel, data[0]))
            setIsLoading(false)
            console.log(data)
        })
    })

    return isLoading ? (
        <React.Fragment>
            <h4>Loading...</h4>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Formik
                initialValues={{
                    personalCarDetailsModel: personalCarDetails,
                }}
                onSubmit={(values) => {
                    alert(JSON.stringify(values))
//                    productService.update("/product/1", JSON.stringify(values.productMembers))
                }}
            >
                {({values, handleSubmit, handleChange, handleBlur, errors}) => (
                    <Form>
                <Box>
                    <Field name={"personalDetailsModel.id"} label={"Azonosító"} component={MyTextField} disabled={true}/>
                </Box>
                <Box>
                    <Field name={`personalDetailsModel.make`} label={"Gyártmány"} component={MyTextField} />
                    <Field name={`personalDetailsModel.model`} label={"Model"} component={MyTextField} />
                    <Field name={`personalDetailsModel.type`} label={"Típus"} component={MyTextField} />
                    <Field name={`personalDetailsModel.productYear`} label={"Gyártási év"} component={MyTextField} />
                    <Field name={`personalDetailsModel.chassisNumber`} label={"Alvázszám"} component={MyTextField} />
                </Box>
                <Box style={{width: "200px", margin: "5px"}}>
                    <Button color={"primary"} variant={"contained"} type={"submit"}>Mentés</Button>
                </Box>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}