import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {Field, FieldArray, Form, Formik} from "formik";
import Box from "@mui/material/Box";
import {MyTextField} from "../../components/MyTextField";
import {Button} from "@mui/material";
import BusinessSetupModel from "../../../models/BusinessSetupModel";
import AddressModel from "../../../models/AddressModel";
import {array, object, string} from 'yup';
import {crudService} from "../../../services/CrudService";
import {ApiEndpoints} from "../../../services/IService";

const validationSchema = object({
    businessSetupModel: object().shape({
        setups: array().of(
            object().shape({
                type: string().max(10)
            })
        )
    })
})


type BusinessSetupProps = {}

export function BusinessSetup(props: BusinessSetupProps) {

    /*
    const formik = useFormik({
        initialValues: {
            businessSetupModel: new BusinessSetupModel(),
        },
//        validationSchema : validationSchema,
        onSubmit: (values) => {
//            alert(JSON.stringify(values))
            productService.update("/product/1", JSON.stringify(values.businessSetupModel))
        },
    })
*/
    const [isLoading, setIsLoading] = useState(true)
    const [businessSetup, setBusinessSetup] = useState(new BusinessSetupModel())

    useEffectOnce(() => {
        crudService.get(ApiEndpoints.BusinessSetup, "", (data: object[]) => {
            setBusinessSetup(new BusinessSetupModel())
                // plainToClass(BusinessSetupModel, data[0]))
            setIsLoading(false)
            console.log(data)
        })
    })

    return isLoading ? (
        <React.Fragment>
            <h4>Loading...</h4>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Formik
                initialValues={{
                    businessSetupModel: businessSetup,
                }}
                onSubmit={(values) => {
                    console.log("hey submit")
                    console.log(values)
                }}
                validationSchema={validationSchema}
            >
                {({values, handleChange, handleBlur, handleSubmit, errors}) => (
                    <Form>
                        <FieldArray name={"businessSetupModel.setups"}>
                            {({insert, remove, push}: any) => (
                                <div>
                                    <div>
                                        {values.businessSetupModel.setups.length > 0 &&
                                            values.businessSetupModel.setups.map((setups, index) => {
                                                return <div key={index}>
                                                    <Box>
                                                        <Field name={`businessSetupModel.setups[${index}].type`}
                                                               component={MyTextField} label={"Típus"}/>
                                                        <Field  name={`businessSetupModel.setups[${index}].description`}
                                                               component={MyTextField} label={"Leírás"}/>
                                                        <Field name={`businessSetupModel.setups[${index}].active`}
                                                               component={MyTextField} label={"Aktív"}/>
                                                        <Button onClick={() => remove(index)}>
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                </div>
                                            })}
                                    </div>
                                    <Button onClick={() => push({
                                        address: new AddressModel(),
                                        type: "3434ssc",
                                        description: "",
                                        active: true
                                    })}>
                                        Add item
                                    </Button>
                                    <pre>
                                        {JSON.stringify(values, null, 2)}
                                        {JSON.stringify(errors, null, 2)}
                                    </pre>
                                </div>

                            )}
                        </FieldArray>
                        <Box style={{width: "200px", margin: "5px"}}>
                            <Button color={"primary"} variant={"contained"} type={"submit"}>Mentés</Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
}