import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {useLocation} from "react-router-dom";

type PriceListProps = {}

export function PriceList (props : PriceListProps) {

    const [myState, setMyState] = useState("")

    console.log('current URL 👉️', window.location.href);
    console.log('current Pathname 👉️', window.location.pathname);

    const location = useLocation()
    console.log('hash', location.hash);
    console.log('pathname', location.pathname);
    console.log('search', location.search);

    useEffectOnce(() => {

    })

    return (
        <React.Fragment>
            <div>Árak:</div>
            <div>Vállalkozások részére: öccá' per év</div>
            <div>Szakmai tanácsadók/ segítők (?!?!): ingyenes</div>
        </React.Fragment>
    )
}