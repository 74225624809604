import IModel from "./IModel";

enum ERights {

    None,
    Create,
    Read,
    Write
}

/*
    Personal,

    CarMechanic,
    CarElectrical,
    CarBodyRepair,
    CarTyreChange,
    CarWash,

    HairDresser,
    NailManicure
*/

class UserModel implements IModel {

    id: string = ""
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    phone: string = ""
    menuItemsBusiness: Array<string> = []
    menuItemsPersonal: Array<string> = []

    getApiUrl(): string {
        return "user/";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }
}

export default UserModel;