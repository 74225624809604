import {FieldProps, getIn, useFormikContext} from "formik";
import React from "react";
import {DateTimePicker} from "@mui/x-date-pickers";

export function MyDateTimePicker({field, form: {errors}, ...props}: FieldProps) {

    const {setFieldValue} = useFormikContext();

    const errorMessage = getIn(errors, field.name)
    return <DateTimePicker ampm={false}
                           ampmInClock={false}
                           value={field.value}
                           format={"yyyy-MM-dd HH:mm:ss"}
                           {...props}
                           slotProps={{ textField:{size:"small", sx:{width: '200px'} }}}
                           onChange={(value) => setFieldValue(field.name, value)}
    />
}