import {FieldProps} from "formik";
import {Select} from "@mui/material";

// https://codesandbox.io/s/915qlr56rp?file=/src/index.tsx:1180-1204
export function MySelect({field, form: {errors}, ...props}: FieldProps) {

    return (
        <Select style={{width: "200px", margin: "5px"}}
                {...field}
                {...props}
                size={"small"}
        >

        </Select>

        )
}
