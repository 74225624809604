import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import {Link} from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CommuteIcon from "@mui/icons-material/Commute";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import userService from "../../services/UserService";
import SettingsIcon from "@mui/icons-material/Settings";

type NavigationItems = {

    id : string,
    label : string,
    icon: JSX.Element,
    link: string,
}

type NavigationArray = {

    id : string,
    children: Array<NavigationItems>,
}

let navigationArrayProvider : Array<NavigationItems> = [
    {id: "B01", label: "Adatok", icon: <ManageAccountsIcon/>, link: "/businessdetails"},
    {id: "B02", label: "Ügyfél meghívás", icon: <ContactMailIcon/>, link: "/businessinviteclient"},
    {id: "B03", label: "Beállások", icon: <CarRepairIcon/>, link: "/businesssetup"},
    {id: "B04", label: "Nyitvatartás", icon: <CalendarMonthIcon/>, link: "/businesstime"},
    {id: "B05", label: "Időpontok", icon: <ScheduleIcon/>, link: "/businesstimetable"},
    {id: "B06", label: "Raktár", icon: <WarehouseIcon/>, link: "/businesswarehouse"},
]

let navigationArrayClient : Array<NavigationItems> = [
    {id: "P01", label: "Személyes adatok", icon: <ManageAccountsIcon/>, link: "/personaldetails"},
    {id: "P02", label: "Járműveim", icon: <CommuteIcon/>, link: "/personalcars"},
    {id: "P04", label: "Időpont foglalás", icon: <EventAvailableIcon/>, link: "/personalappointment"},
    {id: "P05", label: "Foglalások", icon: <CalendarMonthIcon/>, link: "/personaltimetable"},
]

function createNavigationCategories() : Array<NavigationArray> {

    const result: Array<NavigationArray> = [];

    result.push({
        id: '',
        children: [
            {id: "000", label:  "Főoldal", icon: <HomeIcon/>, link: "/"},
        ],
    })
    if (userService.getNavigator()) {
        result.push({
            id: 'Gépjármű szervíz',
            children: navigationArrayProvider,
        })
    }
    if (userService.getNavigator()) {
        result.push({
            id: 'Saját adataim',
            children: navigationArrayClient
        })
    }

    result.push({
        id: 'Teszt',
        children: [
            {id: "X01", label:  "Product form", icon: <SettingsIcon/>, link: "/product_form"},
            {id: "X02", label:  "My Material", icon: <SettingsIcon/>, link: "/my_material"},
        ],
    })

    return result
}

const navigationCategories = createNavigationCategories()

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};

const link = {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: 20,
    "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
    },
};

type NavigatorState = {

    selectionId: string
}

export default class Navigator extends React.Component<DrawerProps, NavigatorState> {

    other: DrawerProps;

    constructor(props: DrawerProps) {
        super(props);
        this.state = {
            selectionId: ""
        };
        this.other = props;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Drawer variant="permanent" {...this.other}>
                <List disablePadding>
                    <ListItem sx={{...item, ...itemCategory, fontSize: 22, color: '#fff'}}>
                        ERP
                    </ListItem>
                    {navigationCategories.map(({id, children}) => (
                        <Box key={id} sx={{bgcolor: '#101F33'}}>
                            <ListItem sx={{py: 2, px: 3}}>
                                <ListItemText sx={{color: '#fff'}}>{id}</ListItemText>
                            </ListItem>
                            {children.map(({id: childId, label, icon, link}) => (
                                <Link key={childId} to={link} style={{ textDecoration: 'none' }}>
                                    <ListItem  disablePadding key={childId}>
                                        <ListItemButton selected={this.state.selectionId === childId} sx={item}
                                                        onClick={() => this.setState({selectionId: childId})}>
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText>{label}</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            ))}
                            <Divider sx={{mt: 2}}/>
                        </Box>
                    ))}
                </List>
            </Drawer>
        );
    }
}
