import IModel from "./IModel";

enum EQuantitativeUnit {
    None,
    Month,
    Hour,
    Occasion, // alkalom
    Piece,
    Gram,
    kiloGram,
}

class ProductModel implements IModel {

    id: number = 0;
    name: string = "";                       // megnevezes / model kod
    isicCode: string = "";                   // SZJ/VTSZ szam
    description: string = "";
    unitNetValue: number = 0;               // egysegar
    vat: number = 0;                          // EVat
    quantitativeUnit: EQuantitativeUnit = EQuantitativeUnit.None;
    // product specific
    productGroupId: number = 0;
    productGroupName: string = "";
    manufacturer: string = "";               // Gyarto'
    barcodeEAN: string = "";                 // European Article Number
    barcodeUPC: string = "";                 // Universal models.warehouse.Product Code
    unitSize: number = 0;
    unitSize_2: string = "";
    unitSize_3: string = "";
    unitNetWeight: number = 0;
    unitGrossWeight: number = 0;
    picture: string = "";
    
    constructor() {
        
    }
    
    getApiUrl(): string {
        return "products/";
    }

    getMembers() : object[] {

        return [
            { field: 'id', headerName: 'Azonosító', position: 0 },
            { field: 'name', headerName: 'Név', position: 1},
            { field: 'isicCode', headerName: 'SZJ/VTSZ szam', position: 1 },
            { field: 'description', headerName: 'Leírás', position: 1 },
            { field: 'unitNetValue', headerName: 'Egységár', position: 1 },
            { field: 'vat', headerName: 'ÁFA', position: 1 },
            { field: 'quantitativeUnit', headerName: 'Menny. Egy.', position: 0 },
            { field: 'productGroupId', headerName: 'Termék csoport kód', position: 1 },
            { field: 'productGroupName', headerName: 'Termék csoport', position: 1 },
            { field: 'manufacturer', headerName: 'Gyártó', position: 1 },
            { field: 'barcodeEAN', headerName: 'EAN kód', position: 1 },
            { field: 'barcodeUPC', headerName: 'UPC kód', position: 1 },
            { field: 'unitSize', headerName: 'Méret', position: 1 },
            { field: 'unitSize_2', headerName: 'Méret 2', position: 1 },
            { field: 'unitSize_3', headerName: 'Méret 3', position: 1 },
            { field: 'unitNetWeight', headerName: 'Nettó tömeg', position: 0 },
            { field: 'unitGrossWeight', headerName: 'Bruttó tömeg', position: 1 },
            { field: 'picture",', headerName: 'Kép', position: 0 },
        ]
    }
    
    parseData(): string {
        return "";
    }
}

export default ProductModel;