import IModel from "./IModel";
import {MyTableCellRenderer} from "../pages/components/MyTableCellRenderer";

class PersonalCarsModel implements IModel {

    getApiUrl(): string {
        // TODO
        return "products/";
    }

    getMembers(): object[] {
        return [
            { field: 'id', headerName: 'Azonosító', cellRenderer: MyTableCellRenderer, position: 0 },
            { field: 'name', headerName: 'Gyártási év', position: 1},
            { field: 'isicCode', headerName: 'Márka', position: 1 },
            { field: 'description', headerName: 'Modell', position: 1 },
            { field: 'unitNetValue', headerName: 'Kivitel', position: 1 },
            { field: 'vat', headerName: 'Üzemanyag', position: 1 },
            { field: 'quantitativeUnit', headerName: 'Alvázszám', position: 0 },
            { field: 'productGroupId', headerName: 'Rendszám', position: 1 },
        ]
    }

    parseData(): string {
        return "";
    }
}

export default PersonalCarsModel