import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import CookieConsent from "../CookieConsent";
import Box from "@mui/material/Box";

export function Copyright() {
    return (
        <Box component="footer" sx={{p: 2, bgcolor: 'rgb(36, 52, 77)'}}>
        <Typography variant="body2" color="gray" align="center" >
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}.
            <CookieConsent cookieName={"ConsentUse"} />
        </Typography>
        </Box>
    );
}