import IModel from "../models/IModel";

export enum ApiEndpoints {
    BusinessDetails     = "business_details",
    BusinessInviteClient= "business_invite_client",
    BusinessSetup       = "business_setup",
    BusinessTime        = "business_time",
    BusinessTimetable   = "business_timetable",
    BusinessWarehouse   = "business_warehouse",
    PersonalDetails     = "personal_details",
    PersonalCars        = "personal_cars",
    PersonalCarDetails  = "personal_car_details",
    PersonalAppointment = "personal_appointment",
    PersonalTimetable   = "personal_timetable",
    Product             = "product",
}

export interface IService {
    // CRUD
    post(api: ApiEndpoints, data: IModel, callback: (status : number, data: object[]) => void): void
    get(api : ApiEndpoints, param : string | undefined, callback : (data : object[]) => void) : void
    patch(api : ApiEndpoints, id : string, data : IModel) : void
    delete(api : ApiEndpoints, id : string) : void
}