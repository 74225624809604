import IModel from "./IModel";
import AddressModel from "./AddressModel";
import ContactModel from "./ContactModel";

enum EBeallasTipus {

    akna,
    keziemelo,
    hidraulikusemelo,
}

type BellasTODO = {

    id: number
    szama: number
    nev: string
    tipus: EBeallasTipus
}

enum EAddressType {

    NotSet,
    RegisteredOffice,   // szekhely             ceg
    Service,            // szolgaltatas cim     ceg
    Communication,      // kapcsolat            ceg
    Permanent,          // allando lakcim       maganszemely
    Mailing,            // levelezesi cim       maganszemely
}

enum EBusinessType {

    None,
    Hobbyist,
    SelfEmployed,
    Limited,
}

class BusinessDetailsModel implements IModel {

    name: string = ""
    businessType: EBusinessType = EBusinessType.None
    taxNumber: string = ""                  // Company or self-employed
    //taxPublicNumber : string = ""           // EU tax number | VIES checked
    addresses: Array<EAddressType | AddressModel> = []
    contacts: Array<ContactModel> = []

    getApiUrl(): string {
        return "";
    }

    getMembers(): object[] {
        return [];
    }

    parseData(): string {
        return "";
    }
}

export default BusinessDetailsModel