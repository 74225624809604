import * as React from 'react'
import {useCallback, useState} from 'react'

import '../index.css'
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {ApiEndpoints} from "../../services/IService";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {crudService} from "../../services/CrudService";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";


type GenericTableProps = {
    columnDefs: object[]
    apiEndpoint: ApiEndpoints
}

//https://reactjs.org/tutorial/tutorial.html#setup-for-the-tutorial
export function GenericTable(props: GenericTableProps) {

    const [rowData, setRowData] = useState<object[]>([])
    const [rowSelected, setRowSelected] = useState(false)

    useEffectOnce(() => {
        crudService.get(props.apiEndpoint, "", (data) => {
            //console.log(plainToClass(ProjectModel, data))
            setRowData(data)
        })
    })
    let gridRef = React.createRef<AgGridReact>();

    function firstDataRenderedCallback() {
        gridRef?.current?.api.sizeColumnsToFit({
            defaultMinWidth: 100
        })
    }

    const onGridReady = useCallback((params: any) => {
        // console.log("size to fit")
        //gridRef?.current?.api.sizeColumnsToFit()
    }, [])

    return (
        <Grid container>
            <Grid item sm={2} sx={{display: 'flex'}}>
                <Box sx={{p: 0, m: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column',}}>
                        <Button color={"primary"} variant={"contained"}>
                            Új felvitel
                        </Button>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column',}}>
                        <Button color={"secondary"} variant={"contained"} disabled={!rowSelected}>
                            Szerkesztés
                        </Button>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column',}}>
                        <Button color={"error"} variant={"contained"} disabled={!rowSelected} >
                            Törlés
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={10}>
                <Box m={1} p={1}>
                    <div className="p-2">
                        <div className="ag-theme-alpine-dark" style={{height: 400, width: "-webkit-fit-content"}}>
                            <AgGridReact
                                ref={gridRef}
                                animateRows={true}
                                enableRangeSelection={false}
                                rowSelection={"single"}
                                onFirstDataRendered={firstDataRenderedCallback}
                                rowData={rowData}
                                columnDefs={props.columnDefs}
                                onGridReady={onGridReady}
                                onRowSelected={(event: RowSelectedEvent) => {setRowSelected(true); console.log(event)}}
                            />
                        </div>
                    </div>
                </Box>
            </Grid>
        </Grid>
    )
}
