import React from "react";
import {TextField} from "@mui/material";
import {FieldProps, getIn} from "formik";
import Box from "@mui/material/Box";
/*
type MyTextFieldProps = {

    id: string,
    name: string,
    label: string,
    value: {},
    onChange: (e: ChangeEvent<any>) => void,
    disabled?: boolean,
}

export function MyTextField(props: MyTextFieldProps) {

    return <TextField style={{width: "200px", margin: "5px"}}
                      id={props.id}
                      name={props.name}
                      label={props.label}
                      value={props.value}
                      onChange={props.onChange}
                      size={"small"}
                      disabled={props.disabled}
    />
}
 */

// https://www.youtube.com/watch?v=FD50LPJ6bjE
export function MyTextField({field, form: {errors}, ...props}: FieldProps) {

    const errorMessage = getIn(errors, field.name)
    return <Box><TextField style={{width: "200px", margin: "5px"}}
                        size={"small"}
                        {...field}
                        {...props}
                        helperText={errorMessage}
                        error={!!errorMessage}
    /></Box>
}
