import React, {useState} from "react";
import PersonalCarsModel from "../../../models/PersonalCarsModel";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {crudService} from "../../../services/CrudService";
import {ApiEndpoints} from "../../../services/IService";
import {plainToClass} from "class-transformer";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Field, Form, Formik} from "formik";
import {MyTextField} from "../../components/MyTextField";
import {Button} from "@mui/material";

type PersonalCarsProps = {}

export function PersonalCars(_: PersonalCarsProps) {

    const cars = new PersonalCarsModel()
    const [isLoading, setIsLoading] = useState(true)
    const [personalCars, setPersonalCars] = useState(new PersonalCarsModel())

    useEffectOnce(() => {
        crudService.get(ApiEndpoints.PersonalCarDetails, "", (data: object[]) => {
            setPersonalCars(plainToClass(PersonalCarsModel, data[0]))
            setIsLoading(false)
            console.log(data)
        })
    })
    return isLoading ? (
        <React.Fragment>
            <h4>Loading...</h4>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item sm={8}>
                    <Paper>
                        <Box m={1} p={1}>
                            <Formik
                                initialValues={{
                                    personalCarDetailsModel: String,
                                }}
                                onSubmit={(values) => {
                                    alert(JSON.stringify(values))
//                    productService.update("/product/1", JSON.stringify(values.productMembers))
                                }}
                            >
                                {({values, handleSubmit, handleChange, handleBlur, errors}) => (
                                    <Form>
                                        <Box>
                                            <h3>Saját járművem adatai</h3>
                                            <Field name={"personalDetailsModel.id"} label={"Azonosító"} component={MyTextField} disabled={true}/>
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                            <Field name={`personalDetailsModel.chassisNumber`} label={"Gyártmány"} component={MyTextField}/>
                                            <Field name={`personalDetailsModel.`} label={"Típus"} component={MyTextField}/>
                                            <Field name={`personalDetailsModel.`} label={"Model"} component={MyTextField}/>
                                            <Field name={`personalDetailsModel.`} label={"Gyártási év"} component={MyTextField}/>
                                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                <Field name={`personalDetailsModel.`} label={"Hengerűrtartalom"} component={MyTextField}/>
                                                <Field name={`personalDetailsModel.`} label={"Teljesítmény"} component={MyTextField}/>
                                            </Box>
                                        </Box>
                                        <Field name={`personalDetailsModel.`} label={"Ajtók száma"} component={MyTextField}/>
                                        <Box style={{width: "200px", margin: "5px"}}>
                                            <Button color={"primary"} variant={"contained"} type={"submit"}>Mentés</Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

/*
            <GenericTable date={new Date()} count={5} message={"my message"} myModel={cars} />

 */