import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {useLocation} from "react-router-dom";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import oauthService from "../../services/OauthService";

type VerifyEmailProps = {}

export function VerifyEmail (props : VerifyEmailProps) {

    const [myState, setMyState] = useState("")

    console.log('current URL 👉️', window.location.href);
    console.log('current Pathname 👉️', window.location.pathname);

    const location = useLocation()
    console.log('hash', location.hash);
    console.log('pathname', location.pathname);
    console.log('search', location.search);

    useEffectOnce(() => {

    })

    return (
        <React.Fragment>
            <div>Az e-mail cím még nem került visszaellenőrzésre - </div>
            <Box>
                <Button color={"primary"} variant={"contained"} type={"button"} onClick={()=>{
                    window.location.reload();
                }}>Újratöltés</Button>
            </Box>
            <Box>
                <Button color={"primary"} variant={"contained"} type={"button"} onClick={()=>{
                    oauthService.logout()
                }}>Kilépés</Button>
            </Box>
        </React.Fragment>
    )
}