import UserModel from "../models/UserModel";
import oauthService from "./OauthService";
import {baseUrl, HttpService} from "./HttpService";
import {ApiEndpoints, IService} from "./IService";
import {AxiosResponse} from "axios";

class UserService extends HttpService implements IService {

    data: UserModel

    constructor() {
        super()
        this.data = new UserModel()
    }

    post(api : ApiEndpoints, data: UserModel): void {
        throw new Error("Method not implemented.")
    }
    get(): UserModel {
        this.httpClient.get(baseUrl + "hello")
            .then((resp: AxiosResponse) => {
                console.log(resp.data)
                //(resp.data)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return new UserModel()
    }
    patch(api : ApiEndpoints, id: string, data: UserModel): void {
        throw new Error("Method not implemented.")
    }
    delete(api : ApiEndpoints, id: string): void {
        throw new Error("Method not implemented.")
    }
    initialize() {
        /*
                this.httpClient.get(this.data.getApiUrl(), (data) => {
                    this.data = plainToInstance(UserModel, data[0])
                })

         */
    }
    isUserValidated() {

        return oauthService.isEmailVerified()
    }
    getNavigator() : Array<string> {

        return []
    }
}

const userService = new UserService();
export default userService;