import React, {useState} from "react";
import {useEffectOnce} from "ag-grid-react/lib/reactUi/useEffectOnce";
import {useFormik} from "formik";
import Box from "@mui/material/Box";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import Paper from "@mui/material/Paper";

let myId = 0

type Record = {
    id: number
    value: string
}

type BusinessInviteClientProps = {
}

export function BusinessInviteClient (_ : BusinessInviteClientProps) {

    const [email, setEmail] = useState("")
    const formik = useFormik({
        initialValues: {
            emails : new Array<Record>()
        },
//        validationSchema : validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values))
//            productService.update("/product/1", JSON.stringify(values.xxxxxModel))
        },
    })

    useEffectOnce(() => {
    })

    return (
        <React.Fragment>
            <Box style={{width: "inherit", margin: "5px"}}>
            <div>
                <TextField id={"email"} variant="standard" label="Add Email" type="email" size="small" value={email} onChange={(val) => {
                    setEmail(val.target.value)
                }}/>
                <Button variant="contained" onClick={(_) =>{
                    if (email.length) {
                        const recordsTemp = formik.values.emails
                        recordsTemp.push({id: myId++, value: email})
                        setEmail("")
                        formik.setValues({emails: recordsTemp})
                        console.log(recordsTemp)
                    }
                }}>E-Mail hozzáadása a listához</Button>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <Box style={{width: "inherit", margin: "5px"}}>
                    <TableContainer
                        component={Paper}
                        variant="outlined"
                    >
                        <Table aria-label="demo table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><h4>Meghívandó Ügyfelek E-Mail címei:</h4></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formik.values.emails.map((record) => (
                                    <TableRow key={record.id}>
                                        <TableCell>{record.value}</TableCell>
                                        <TableCell>
                                            <Button type="button" variant={"contained"} color={"warning"} onClick={() => {
                                                const recordsTemp = formik.values.emails.filter(rec => rec.id !== record.id)
                                                formik.setValues({emails: recordsTemp})
                                            }}>
                                                <span aria-hidden="true">X</span>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box style={{width: "200px", margin: "5px"}}>
                    <Button color={"primary"} variant={"contained"} type={"submit"}
                            disabled={!formik.isValid}>Meghívók kiküldése</Button>
                </Box>
            </form>
            </Box>
        </React.Fragment>
    )
}